import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Section from '../components/section'
import { graphql } from 'gatsby'

const IndexPage = ({ data, location }) => {
     const { wpPage } = data
     // const { sections } = wpPage.cfPage
     const { titleTag, metaDescription, canonicalTagOverride, robotsDirective } = wpPage.cfPage.pageSeoFields
     const { ogTitle, ogDescription, ogImage, ogType, twitterCard } = wpPage.cfPage.pageSocialOpenGraphFields
     const classBody = wpPage.title.split(' ').join('-').toLowerCase()
     const canonical = process.env.GATSBY_SITE_URL + location.pathname
     const homePageSections = [
          {
               sectionName: 'Section 1 - Hero',
               showSection: 'Yes',
               section: [
                    {
                         cfSection: {
                              fullWidthBlocks: [
                                   {
                                        cfBlock: {
                                             blockType: 'New Hero Section',
                                        },
                                   },
                              ],
                              sectionSettings: {
                                   spaceAbove: 'None',
                                   spaceBelow: 'None',
                              },
                         },
                    },
               ],
          },
          {
               sectionName: 'Section 2 - Quote',
               showSection: 'Yes',
               section: [
                    {
                         cfSection: {
                              fullWidthBlocks: [
                                   {
                                        cfBlock: {
                                             blockType: 'Hero Quote',
                                             heroFields: {
                                                  quote: `<p>We help serious marketers take back control of their data! No more broken, inconsistent, anonymous marketing data. Centralize a reliable strategy, get better data, and tie it to revenue and pipeline. Join us today! - I guarantee you'll be amazed.</p>`,
                                                  meta: { name: `Zach Randall`, position: `Founder & CEO` },
                                                  avatar: { src: '/images/hero-image.png', alt: 'Zach Randall' },
                                                  textSize: 'Medium',
                                             },
                                        },
                                   },
                              ],
                              sectionSettings: {
                                   spaceAbove: 'None',
                                   spaceBelow: 'None',
                              },
                         },
                    },
               ],
          },
          {
               sectionName: 'Section 3 - Headline & Body Text',
               showSection: 'Yes',
               section: [
                    {
                         cfSection: {
                              fullWidthBlocks: [
                                   {
                                        cfBlock: {
                                             blockType: 'Headline & Body Text',
                                             headlineBodyFields: {
                                                  headlines: [
                                                       { headlineAlignment: 'Center', headlineType: 'H3', headlineValue: 'What is ListenLayer?' },
                                                  ],
                                                  body: [
                                                       {
                                                            bodyCopy: `<p>ListenLayer is the <strong style="color:#20A8D8;">marketer's single source of truth</strong> - a centralized platform to define and automate your measurement strategy, enrich and deliver data where you need it, and tie marketing activity to <u>real people, companies, and revenue</u> - so you can learn what works and optimize outcomes.</p><p>With built-in consent and privacy, server-side tagging, and cookieless architecture, ListenLayer is more than just web analytics - it's the future of marketing data.</p>`,
                                                            bodyCopyAlignment: 'Center',
                                                            howTallYouWantTheSpaceToBe: null,
                                                       },
                                                  ],
                                                  width: 'Large',
                                             },
                                        },
                                   },
                              ],
                              sectionSettings: {
                                   spaceAbove: 'None',
                                   spaceBelow: 'None',
                              },
                         },
                    },
               ],
          },
          {
               sectionName: 'Section 4 - Intro Capabilities',
               showSection: 'Yes',
               section: [
                    {
                         cfSection: {
                              fullWidthBlocks: [
                                   {
                                        cfBlock: {
                                             blockType: 'Intro Capabilities',
                                        },
                                   },
                              ],
                              sectionSettings: {
                                   spaceAbove: 'None',
                                   spaceBelow: 'None',
                              },
                         },
                    },
               ],
          },
          {
               sectionName: 'Section 5 - Headline & Body Text',
               showSection: 'Yes',
               section: [
                    {
                         cfSection: {
                              fullWidthBlocks: [
                                   {
                                        cfBlock: {
                                             blockType: 'Headline & Body Text',
                                             headlineBodyFields: {
                                                  headlines: [
                                                       { headlineAlignment: 'Center', headlineType: 'H3', headlineValue: 'Who Uses ListenLayer?' },
                                                  ],
                                                  body: [
                                                       {
                                                            bodyCopy: `<p>Marketing teams from agencies and mid-sized companies to global enterprises benefit from ListenLayer every day.</p>`,
                                                            bodyCopyAlignment: 'Center',
                                                            howTallYouWantTheSpaceToBe: null,
                                                       },
                                                  ],
                                                  width: 'Medium Large',
                                                  customClass: 'home-intro',
                                             },
                                        },
                                   },
                              ],
                              sectionSettings: {
                                   spaceAbove: 'None',
                                   spaceBelow: 'None',
                              },
                         },
                    },
               ],
          },
          {
               sectionName: 'Section 6 - Marketing Partners',
               showSection: 'Yes',
               section: [
                    {
                         cfSection: {
                              fullWidthBlocks: [
                                   {
                                        cfBlock: {
                                             blockType: 'Marketing Partners',
                                        },
                                   },
                              ],
                              sectionSettings: {
                                   spaceAbove: 'None',
                                   spaceBelow: 'None',
                              },
                         },
                    },
               ],
          },
          {
               sectionName: 'Section 7 - Testimonials',
               showSection: 'Yes',
               section: [
                    {
                         cfSection: {
                              fullWidthBlocks: [
                                   {
                                        cfBlock: {
                                             blockType: 'Testimonials',
                                        },
                                   },
                              ],
                              sectionSettings: {
                                   spaceAbove: 'None',
                                   spaceBelow: 'None',
                              },
                         },
                    },
               ],
          },
          {
               sectionName: 'Section 8 - Supper Power Listener',
               showSection: 'Yes',
               section: [
                    {
                         cfSection: {
                              fullWidthBlocks: [
                                   {
                                        cfBlock: {
                                             blockType: 'Supper Power Listener',
                                        },
                                   },
                              ],
                              sectionSettings: {
                                   spaceAbove: 'None',
                                   spaceBelow: 'None',
                              },
                         },
                    },
               ],
          },
          {
               sectionName: 'Section 9 - Advantage',
               showSection: 'Yes',
               section: [
                    {
                         cfSection: {
                              fullWidthBlocks: [
                                   {
                                        cfBlock: {
                                             blockType: 'Advantage',
                                        },
                                   },
                              ],
                              sectionSettings: {
                                   spaceAbove: 'None',
                                   spaceBelow: 'None',
                              },
                         },
                    },
               ],
          },
          {
               sectionName: 'Section 10 - How To Get Started',
               showSection: 'Yes',
               section: [
                    {
                         cfSection: {
                              fullWidthBlocks: [
                                   {
                                        cfBlock: {
                                             blockType: 'How To Get Started',
                                        },
                                   },
                              ],
                              sectionSettings: {
                                   spaceAbove: 'None',
                                   spaceBelow: 'None',
                              },
                         },
                    },
               ],
          },
     ]

     return (
          <Layout>
               <SEO
                    title={titleTag}
                    description={metaDescription}
                    canonical={canonicalTagOverride || canonical}
                    robotsDirective={robotsDirective}
                    ogTitle={ogTitle}
                    ogDescription={ogDescription}
                    ogImage={ogImage}
                    ogType={ogType}
                    twitterCard={twitterCard}
                    classBody={classBody}
               />
               {homePageSections &&
                    homePageSections.map((section, index) => {
                         return <Section key={index} sectionValues={section} />
                    })}
          </Layout>
     )
}

export default IndexPage

export const pageQuery = graphql`
     query PageHome {
          wpPage(slug: { eq: "home" }) {
               ...WordpressPageFields
          }
     }
`
